import React, { useState } from 'react'
import { Box, LabelStyled } from '../Styled/Elements'
import { useTranslation } from 'react-i18next';

const IncreaseDecreasePercentage = () => {
    const { t } = useTranslation();
    const [numerator, setNumerator] = useState('');
    const [denominator, setDenominator] = useState('');
    const [percentage, setPercentage] = useState(null);

    const calculatePercentage = () => {
        const finalValue = parseFloat(numerator);
        const initialValue = parseFloat(denominator);

        if (!isNaN(finalValue) && !isNaN(initialValue) && initialValue !== 0) {
            const result = ((finalValue - initialValue) / initialValue) * 100;
            setPercentage(result.toString());
        } else {
            setPercentage(null);
        }
    };
    return (
        <Box>
            <LabelStyled> {t('decrease')}</LabelStyled>
            <Box className='box-wrapper'>
                <Box className='percentage-field'>
                    <LabelStyled>{t('Form')}</LabelStyled>
                    <input type="text" value={denominator} onChange={(e) => setDenominator(e.target.value)} />
                    <LabelStyled>{t('To')}</LabelStyled>

                    <input type="text" value={numerator} onChange={(e) => setNumerator(e.target.value)} />
                    <LabelStyled className='question_mark' > ? </LabelStyled>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-warning' onClick={calculatePercentage}>{t('Calculate')}</button>
                    {/* <button className='claculate-btn btn btn-warning'> {percentage} </button> % */}
                    {percentage !== null && (<button className='claculate-btn btn btn-outline-warning'> {percentage} %</button>)}
                </Box>
            </Box>
        </Box >
    )
}

export default IncreaseDecreasePercentage