import React from 'react'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../Styled/Elements'
import FindValue from './FindValue';
import IncreaseDecreasePercentage from './IncreaseDecreasePercentage';
import FindPercentage from './FindPercentage';
import { Container } from 'react-bootstrap';
import ChangeButton from '../../Languages/ChangeButton'
import { useTranslation } from 'react-i18next';

const Percentage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Container fluid >
                <Box className='content-main-container'>
                    <Box className='percentage'>
                        <MainHeading className='heading-font'> {t('Percentage_Calculator')}</MainHeading>
                        <Box className='my-3'>
                            <FindValue />
                            <FindPercentage />
                            <IncreaseDecreasePercentage />
                        </Box>
                    </Box>
                    <Box className='percentage-content'>
                        <Box>
                            <MainHeading>{t('About')}</MainHeading>
                            <TextStyled>{t('website')}</TextStyled>
                        </Box>
                        <Box className='Faq-content'>
                            <MainHeading>{t('FAQ')}</MainHeading>
                            <Box>
                                <HeadingStyled>{t('percentage')}</HeadingStyled>
                                <TextStyled>{t('percentage_content')}</TextStyled>
                            </Box>
                            <Box>
                                <HeadingStyled>{t('percentage_cal')}</HeadingStyled>
                                <TextStyled>{t('percentage_cal_content')}</TextStyled>
                            </Box>
                            <Box>
                                <HeadingStyled>{t('matter')}</HeadingStyled>
                                <TextStyled>{t('matter_per')}</TextStyled>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <ChangeButton />
        </>
    )
}

export default Percentage