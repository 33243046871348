import React, { useState } from 'react'
import { Box, LabelStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation();
    const [numerator, setNumerator] = useState(null);
    const [denominator, setDenominator] = useState('');
    const [percentage, setPercentage] = useState('');
    const calculateValue = () => {
        const percentageValue = parseFloat(percentage);
        const denominatorValue = parseFloat(denominator);

        if (!isNaN(percentageValue) && !isNaN(denominatorValue) && denominatorValue !== 0) {
            const result = (percentageValue * denominatorValue) / 100;
            // console.log(result.toFixed())
            setNumerator(result.toString());
        } else {
            setNumerator(null);
        }
    };
    return (
        <Box>
            <Box className='box-wrapper my-4'>
                <Box className='percentage-field'>
                    <LabelStyled>{t('What_is')}:</LabelStyled>
                    <input type="text" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
                    <LabelStyled> % </LabelStyled>
                    &nbsp;
                    <LabelStyled>{t('of')}: </LabelStyled>
                    <input type="text" value={denominator} onChange={(e) => setDenominator(e.target.value)} />
                    <LabelStyled className='question_mark'>? </LabelStyled>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-warning' onClick={calculateValue}>{t('Calculate')}</button>
                    {/* <button className='claculate-btn btn btn-warning'> {numerator} </button> */}
                    {numerator !== null && (<button className='claculate-btn btn btn-outline-warning'> {numerator} </button>)}
                </Box>
            </Box>
        </Box>

    )
}

export default FindValue